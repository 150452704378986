import * as React from "react"
import { Link } from "gatsby"

import ErrorImage from "../images/404_error.png"

// styles
const pageStyles = {
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  flexWrap: "wrap",
  gap: "40px",
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  marginTop: "80px",
}

const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}

const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

const linkStyles = {
  color: "#1d4ed8",
  textDecoration: "underline",
}

const imageStyles = {
  maxWidth: 300,
  width: "100%",
  marginBottom: 48,
  borderRadius: 8,
}

// markup
const NotFoundPage = () => (
  <main style={pageStyles}>
    <div>
      <title>Not found</title>
      <h1 style={headingStyles}>Page not found</h1>
      <p style={paragraphStyles}>
        Sorry{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        we couldn&apos;t find what you were looking for.
        <br />
        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in <code style={codeStyles}>src/pages/</code>
            .
            <br />
          </>
        ) : null}
        <Link style={linkStyles} to="/">
          Go to home page
        </Link>
        .
      </p>
    </div>

    <img style={imageStyles} src={ErrorImage} alt="Error 404" />
  </main>
)

export default NotFoundPage
